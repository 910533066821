import { createSlice } from '@reduxjs/toolkit'
//import vmStateJson from '../../../data-sources/admin_vm_state.json';

export const dataloadRoundStateSlice = createSlice({
  name: 'dataloadRound',
  initialState: {
    value: "Select Dataload Round"
  },
  reducers: {
    setDataloadRoundState: (state, action) => {
      state.value = action.payload
    },
    resetDataloadRoundState: (state, action) => {
      state.value = initialState.value;
    }
  }
})
export const { setDataloadRoundState, resetDataloadRoundState } = dataloadRoundStateSlice.actions
export default dataloadRoundStateSlice.reducer

export const selectDataloadRoundState = (state) => state.dataloadRound.value