import { createSlice } from '@reduxjs/toolkit'

export const editModeSlice = createSlice({
  name: 'editMode',
  initialState: {
    value: false
  },
  reducers: {    
    setEditMode: state => {
      state.value = true
    },
    resetEditMode: state => {
      state.value = false
    }
  }
})
export const { setEditMode, resetEditMode } = editModeSlice.actions
export default editModeSlice.reducer

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectEditMode = state => state.editMode.value