import { createSlice } from '@reduxjs/toolkit'

export const constructorsStandingsEditableSlice = createSlice({
  name: 'constructorsStandingsEditable',
  initialState: {
    value: false
  },
  reducers: {
    allowEditToggle: (state, action) => {
      state.value = !state.value
    }
  }
})
export const { allowEditToggle } = constructorsStandingsEditableSlice.actions
export default constructorsStandingsEditableSlice.reducer

export const selectConstructorsStandingsEditableState = (state) => state.constructorsStandingsEditable.value