import { CognitoIdentityProviderClient, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
import cognitoConfig from './cognitoConfig.json';

export const cognitoClient = new CognitoIdentityProviderClient({
  region: cognitoConfig.region,
});

export const signIn = async (username, password) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: cognitoConfig.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');
      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

/*
export function getCurrentUser() {
  return userPool.getCurrentUser();
}

export function isAuthenticated() {
  const user = getCurrentUser();
  if (!user) return false;

  return new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) {
        reject(false);
      } else {
        resolve(session.isValid());
      }
    });
  });
}

export function signOut() {
  const user = getCurrentUser();
  if (user) {
    user.signOut();
  }
  localStorage.removeItem('token');
}

*/