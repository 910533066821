
import * as React from 'react';
import { NavLink, Link } from "react-router-dom";
import StandingsTableClass from './pages/Standingsf1allday';
import { BrowserRouter } from 'react-router-dom'

//pages => standings, titbits, tracks, head-to-head,

class NavBarClass extends React.Component {
    renderNavBar() {
      
      return (
        <>
        <nav className="navbar navbar-expand-lg navbar-light bg-info">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <a className="navbar-brand" href="/">f1allday</a>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                  <NavLink to="/home">
                    <button type="button" className="btn btn-info">Home</button>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/standingstable">
                    <button type="button" className="btn btn-info">Standings</button>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/schedule">
                    <button type="button" className="btn btn-info">Schedule</button>
                  </NavLink>
                </li>
                {<li className="nav-item">
                  <NavLink to="/head-to-head">
                    <button type="button" className="btn btn-info">Head-To-Head</button>
                  </NavLink>
                </li>}
                <li className="nav-item">
                  <NavLink to="/standingstable" className="invisible">
                    <button type="button" className="btn btn-info">Titbits</button>
                  </NavLink>
                </li>
                
                       
                
                <ul>
            </ul>
              </ul>
            </div>
          </div>
        </nav>

        </>
        
      );
    }

    render() {
        return (
            <>
                {this.renderNavBar()}
            </>
        );
      }
}

export default NavBarClass;