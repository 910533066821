import { createSlice } from '@reduxjs/toolkit'
import vmStateJson from '../../../data-sources/admin_vm_state.json';

export const vmStateSlice = createSlice({
  name: 'vmControlState',
  initialState: {
    vmState: vmStateJson.VM_STATE,
    isVmControlDisabled: false
  },
  reducers: {
    setVmState: (state, action) => {
      var prev = state.vmState
      state.vmState = action.payload
      

      /*if (prev == 'EC2_STARTED'){
        state.vmState =  'EC2_STOPPING'
      }
      else if (prev == 'EC2_STOPPED'){
        state.vmState =  'EC2_STARTING'
      }
      else{
        state.vmState = prev
      }*/

      //console.log(`previous state was ${prev} while new state is ${state.vmState}`)
    },

    updateVmControlSwitchIsDisabled: (state, action) => {
      state.isVmControlDisabled = state.vmState === 'EC2_STARTING' || state.vmState === 'EC2_STOPPING';
    }
  }
})
export const { setVmState, updateVmControlSwitchIsDisabled } = vmStateSlice.actions
export default vmStateSlice.reducer

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectVmState = (state) => state.vmControlState.vmState
export const selectIsVmControlDisabled = state => state.vmControlState.isVmControlDisabled