import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import driversStandingsData from '../data-sources/2023V2_drivers_standings_input.json';
import constructorsStandingsData from '../data-sources/2023V2_constructors_standings_input.json';

import NavBarClass from '../NavBarf1allday';


class StandingsTableClass extends React.Component {

  render() { 

    /*const [tasks, dispatch] = useReducer(
      standingsTableReducer,
      initialStandingsTableState
    );  */ 

    return (
        <> 
          {this.renderTable()}   
               
        </>
    );
  }  

  renderTable() {
    return (
      <>
        <NavBarClass />    

        <div className="div-standingsTableTitle">
          <StandingsTable year="2024 " tableType="Drivers"/> 
        </div>
      </>
      
      
    );
  }
}

class StandingsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = { year: this.props.year,
                   tableType: this.props.tableType };
    
  }

  indicateTableType(elementIdEnabled, elementIdDisabled){
    let enabledElement = document.getElementById(elementIdEnabled)
    let disabledElement = document.getElementById(elementIdDisabled)

    ReactDOM.findDOMNode(enabledElement).style.backgroundColor = 'black'
    ReactDOM.findDOMNode(disabledElement).style.backgroundColor = 'dimgray'

  }

  driversStandingsHandler=(event) => {
    
    this.setState({ tableType: "Drivers"}) //driversStandingsButton
    this.indicateTableType('driversStandingsButton', 'constructorsStandingsButton')

  }
  constructorsStandingsHandler=(event) => {
    
    this.setState({ tableType: "Constructors"})
    this.indicateTableType('constructorsStandingsButton', 'driversStandingsButton')
  }

  renderStandingsTable() {
    return (
      <> 
        <TableTitle year={this.state.year} tableType={this.state.tableType}/>
        <StandingsSelector year={this.state.year} tableType={this.state.tableType} 
                           driversStandingsHandler={this.driversStandingsHandler} 
                           constructorsStandingsHandler={this.constructorsStandingsHandler}
                           indicateTableType={this.indicateTableType}
        />
        <TableBody year={this.state.year} tableType={this.state.tableType}/>     
      </>
    );
  }

  render(){
    return (
      <>
        {this.renderStandingsTable()}
      </>
    )
  }
}

class TableTitle extends React.Component {

  constructor(props) {
    super(props);
  }

  renderTitle() {
    return (
      <> 
        <h3 className="h3-tableTitle">{this.props.year}  {this.props.tableType} Standings</h3>     
      </>
    );
  }

  render(){
    return (
      <>
        {this.renderTitle()}
      </>
    )
  }
}

class StandingsSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = { tableType: this.props.tableType };
  }
  
  componentDidMount() {
    this.props.driversStandingsHandler()
  }
       
  renderStandingsSelector() {
    return (
      <>
        <button id="driversStandingsButton" className="w-50 btn btn-lg btn-secondary" 
                type="button" onClick={() => this.props.driversStandingsHandler()}>Drivers</button>
        <button id="constructorsStandingsButton" className="w-50 btn btn-lg btn-secondary" 
                type="button" onClick={() => this.props.constructorsStandingsHandler()}>Constructors</button>
      </>
    );
  }

  render(){
    return (
      <>
        {this.renderStandingsSelector()}
      </>
    )
  }
}



class TableBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      details: {year: this.props.year,
                tableType: this.props.tableType} 
    };
  }

  renderDriverTableBody() {
    return (
      <> 
        <div className="div-driverStandingsTableClass">
        <table className="table table-striped ">
          <thead>
            <tr className="table-secondary">
              <th>Pos</th>
              <th>Driver</th>
              <th>Team</th>
              <th>Points</th>
              <th>Wins</th>
              <th>Podiums</th>
            </tr>
          </thead>
          <tbody className="table-primary">
            {driversStandingsData.map((item) => (
              <tr key={item.Driver}>
                <td>{item.Pos}</td>
                <td>{item.Driver}</td>
                <td>{item.Team}</td>
                <td>{item.Points}</td>
                <td>{item.Wins}</td>
                <td>{item.Podiums}</td>
              </tr>
            ))}
          </tbody>
        </table>        
      </div>    
      </>
    );
  }

  renderConstructorTableBody() {
    return (
      <> 
        <div className="div-constructorStandingsTableClass">
        <table className="table table-striped">
          <thead>
            <tr className="table-secondary">
              <th>Pos</th>
              <th>Team</th>
              <th>Points</th>
              <th>Wins</th>
              <th>Podiums</th>
            </tr>
          </thead>
          <tbody className="table-primary">
            {constructorsStandingsData.map((item) => (
              <tr key={item.Team}>
                <td>{item.Pos}</td>
                <td>{item.Team}</td>
                <td>{item.Points}</td>
                <td>{item.Wins}</td>
                <td>{item.Podiums}</td>
              </tr>
            ))}
          </tbody>
        </table>        
      </div>    
      </>
    );
  }

  render(){
    if (this.props.tableType == 'Drivers'){
      return (
        <>
          {this.renderDriverTableBody()}
        </>
      )
    }
    if (this.props.tableType == 'Constructors'){
      return (
        <>
          {this.renderConstructorTableBody()}
        </>
      )
    }
    return (
      <>
        {this.renderDriverTableBody()}
        {this.renderConstructorTableBody()}
      </>
    )
  }
}

export default StandingsTableClass;