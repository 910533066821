
import React from 'react';
import newsdata from '../news2022.json'

import NavBarClass from '../NavBarf1allday';
import StandingsTableClass from './Standingsf1allday';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


class MainRootClass extends React.Component {
    
    render() {
        return (
            <>            
                <NavBarClass />
                <GridClass />
            </>
        );
    }
}

class GridClass extends React.Component {

  constructor(props) {
    super(props);
  }
  
  iterateNewsEntries(){
    const newsItems = newsdata.map((news_row, current_index) => 
        <SquareClass paper_name={news_row['paper_name']} 
                     headline={news_row['headline']}
                     headline_link={news_row['headline_link']}  
                     image_link={news_row['image_link']} 
                     hours_ago={news_row['hours_ago']} 
                     paper_home_page={news_row['paper_home_page']}
                     index={current_index}> 
        </SquareClass>);

    return newsItems    
  }

  splitIntoArrays(){

    var newsEntries = this.iterateNewsEntries()
    var row1 = new Map()
    var rowsArray = []
    let i = 0

    for (let entry in newsEntries ){

      if ((i % 3) == 0){        
        row1.set("square1", newsEntries[entry])
      }
      else if (i % 3 == 1){
        row1.set("square2", newsEntries[entry] )
      }
      else if (i % 3 == 2){
        row1.set("square3", newsEntries[entry] )
      }

      if ( (row1.size == 3) || (i == (newsEntries.length - 1))){
        rowsArray.push(row1)
        row1 = new Map()
      }

      i++            
    }
    

    var rows = []

    for (let i in rowsArray){
      var row = <RowClass square1={rowsArray[i].get('square1')} square2={rowsArray[i].get('square2')} square3={rowsArray[i].get('square3')} key={i}>  </RowClass>
    
      rows.push(row)
    }

    return rows;
  }

  render() {
      return (
        <>
          <div className="container-fluid">
            {this.splitIntoArrays()}
          </div>          
        </>
      );
    }
}


class RowClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      squares: {square1: this.props.square1,
                square2: this.props.square2,
                square3: this.props.square3
              } 
      };
  }
  renderRow() {
    return (
      <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          {this.state.squares.square1}
          {this.state.squares.square2}
          {this.state.squares.square3}
        </div>
      </div> 
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderRow()}
      </div>
    );
  }

}


class SquareClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      details: {paper_name: this.props.paper_name,
                  headline: this.props.headline,
                  image_link: this.props.image_link,
                  headline_link: this.props.headline_link,
                  paper_home_page: this.props.paper_home_page,
                  hours_ago: this.props.hours_ago} 
      };
  }

  renderSquare() {
    //var fullHeadline = "@" + this.state.details.newspaper + " " +  this.state.details.headline
    return (
      <>      
        <button className="btn-light-whole-square col-lg-12 col-xl-3 " >          

        <SquareTitleTimeClass paper_name={this.state.details.paper_name}
                              headline_link={this.props.paper_home_page}
                              hours_ago={this.state.details.hours_ago}> 
        </SquareTitleTimeClass>

          <div href={this.state.details.headline_link} className="btn-light-image-and-link" target="_blank">
            
            <img className="img-fluid" src={this.state.details.image_link} alt={this.state.details.paper_name}></img>
            <a href={this.state.details.headline_link} className="btn-headline-text"  target="_blank">{this.state.details.headline}</a>
          </div>
        </button>
        
      </>
    );
  }

  render(){
    return (
      <>
        {this.renderSquare()}
      </>
    )
  }
}

class SquareTitleTimeClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      details: {paper_name: this.props.paper_name,
                headline_link: this.props.headline_link,
                hours_ago: this.props.hours_ago} 
    };
  }

  renderSquareTitleTime() {
    //var fullHeadline = "@" + this.state.details.newspaper + " " +  this.state.details.headline
    //<h4>@{this.state.details.paper_name}    {this.state.details.hours_ago} </h4>
    
    var headlineHoursAgo = "@" + this.state.details.paper_name  + this.state.details.hours_ago
    //<a href={this.state.details.headline_link} class="btn"  target="_blank">{headlineHoursAgo}</a>
    return (
      <>
        <a href={this.state.details.headline_link} className="btn-papername-time-text"  target="_blank">@{this.state.details.paper_name}</a>
        <h6>{this.state.details.hours_ago} </h6>
      </>
    );
  }

  render(){
    return (
      <>
        {this.renderSquareTitleTime()}
      </>
    )
  }
}



export default MainRootClass;