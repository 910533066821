import { createSlice } from '@reduxjs/toolkit'
//import vmStateJson from '../../../data-sources/admin_vm_state.json';

export const dataloadYearStateSlice = createSlice({
  name: 'dataloadYear',
  initialState: {
    value: "Select Dataload Year"
  },
  reducers: {
    setDataloadYearState: (state, action) => {
      state.value = action.payload
    },
    resetDataloadYearState: (state, action) => {
      state.value = initialState.value;
    }
  }
})
export const { setDataloadYearState, resetDataloadYearState } = dataloadYearStateSlice.actions
export default dataloadYearStateSlice.reducer

export const selectDataloadYearState = (state) => state.dataloadYear.value