import { useState, useRef, useEffect } from 'react';
import { Fragment } from 'react'
import NavBarClass from '../NavBarf1allday';

import grandPrixScheduleData from '../data-sources/grand_prix_schedule.json';

import { DateTime } from "luxon";

import ReactDOM from "react-dom"
import TimezoneSelect from "react-timezone-select"


export default function ScheduleClass() {
    //https://stackoverflow.com/questions/16448754/how-to-use-a-custom-time-in-browser-to-test-for-client-vs-server-time-difference
    //simulate diff timezones
    //updated Hungary

    /*const [selectedTimezone, setSelectedTimezone] = useState({
        "value": "Europe/London",
        "label": "(GMT+1:00) Edinburgh, London",
        "offset": 1,
        "abbrev": "BST",
        "altName": "British Summer Time"
    })  */

    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    const TimezoneSelector = () => {       
      
        return (
          <div className="timezoneSelector-tz sticky-top">
              <TimezoneSelect
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
          </div>          
        )
    }
    

  return (
    <div className="div-fullSchedulePageIncNavbar">
        <NavBarClass/>  
        
        <div className="div-scheduleTitle" >
            <TimezoneSelector/>
            <FullScheduleV2 timezone={selectedTimezone} nextRace="Drivers"/>             
        </div>
        
    </div>
  );
}

function FullScheduleV2({timezone, nextRace }){
    //Use bootstrap this time!
    var isCollapsed = false
    var nextRace = false 

    const scrollToNextRace = () => {
        const lastChildElement = ref.current?.lastElementChild;
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });
      };

    return (<div className="container div-scheduleEntry ">
                

                {grandPrixScheduleData.map((item) => (
                    <Fragment key={item.RaceName.Title}>

                        <div className="div-single-race-details justify-content-center " >
                            <EventName eventNameTitle={item}>

                            </EventName>
                            
                            <div className="row justify-content-center tr-raceAndTrackInfo" >     
                                <div className="col-xs-12  col-xl-6">   
                                    <figure>       
                                        <img className="img-fluid-circuit" src={item.circuit_image_link} alt={item.circuit_image_link}></img>
                                    </figure>
                                </div>
                                <div className="col-xs-12 col-xl-6">
                                    <RaceWeekend raceWeekendEvents={item} isCollapsed={isCollapsed}
                                        nextRace={item.day1Event1.Title} timeZone={timezone}>
                                    </RaceWeekend>
                                    <figure >       
                                        <img className="img-fluid-flag" src={item.country_flag_image_link} alt={item.circuit_image_link}></img>
                                    </figure>                                    
                                </div>                                    
                            </div>
                                                
                        </div>
                        <hr className="bg-danger border-2 border-top border-danger hr-race-divider"></hr> 
                    </Fragment>
                    
                ))} 
                
            </div>);
}

function EventName({eventNameTitle}){
    
    //var nextRace = "British"
    var isNextRace = eventNameTitle.RaceName.next;
    var race = eventNameTitle.RaceName.Title

    const headingRef = useRef()

    useEffect(() => {
        if (isNextRace === "true") {
          headingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, []);

    return (
        <div>
          {(isNextRace === "true") ? (
            <>
                <h3 ref={headingRef} className="h3-country-next">{race}&nbsp;&nbsp;</h3>
                <span className="span-country">Next</span>
            </>
            
          ) : (
            <h3 className="h3-country">{race}</h3>
          )}
        </div>
      );
}


/*function EventName({eventNameTitle}){
    
    var nextRace = "British"
    var race = eventNameTitle.RaceName.Title

    const headingRef = useRef()

    useEffect(() => {
        if (nextRace === race) {
          headingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, []);

    return (
        <div>
          {(nextRace === race) ? (
            <>
                <h3 ref={headingRef} className="h3-country-next">{race}&nbsp;&nbsp;</h3>
                <span className="span-country">Next</span>
            </>
            
          ) : (
            <h3 className="h3-country">{race}</h3>
          )}
        </div>
      );
}
*/

function convertToTimeZone(currentDateTime, newTimezone) {
    
    var oldDateTime = DateTime.fromISO(currentDateTime, {zone: 'utc'})
    oldDateTime = oldDateTime.setZone(newTimezone);
    return {eventDate:`${oldDateTime.toLocaleString({ month: 'short', day: '2-digit' })}`, 
            eventTime:`${oldDateTime.toLocaleString(DateTime.TIME_SIMPLE)}`};
}



function RaceWeekend({raceWeekendEvents, isCollapsed, isNextRace, timeZone }){
    

    var d1E1 = convertToTimeZone(raceWeekendEvents.day1Event1.DateAndTime, timeZone.value );
    var d1E2 = convertToTimeZone(raceWeekendEvents.day1Event2.DateAndTime, timeZone.value );
    var d2E1 = convertToTimeZone(raceWeekendEvents.day2Event1.DateAndTime, timeZone.value );
    var d2E2 = convertToTimeZone(raceWeekendEvents.day2Event2.DateAndTime, timeZone.value );
    var d3E = convertToTimeZone(raceWeekendEvents.day3Event.DateAndTime, timeZone.value );
    
    //console.log(`d1e1 is ${d1E1.eventDate}`);
        
    return (
        
        <>
            <table className="table table-striped table-raceweekend-events">
                <tbody className="table-primary">
                    <tr >
                        <td>{raceWeekendEvents.day1Event1.Title}</td>
                        <td>{d1E1.eventDate}</td>
                        <td>{d1E1.eventTime}</td>
                    </tr>
                    <tr >
                        <td>{raceWeekendEvents.day1Event2.Title}</td>
                        <td>{d1E2.eventDate}</td>
                        <td>{d1E2.eventTime}</td>
                    </tr>
                    <tr> 
                        <td>{raceWeekendEvents.day2Event1.Title}</td>  
                        <td>{d2E1.eventDate}</td>
                        <td>{d2E1.eventTime}</td>
                    </tr> 
                    <tr> 
                        <td>{raceWeekendEvents.day2Event2.Title}</td>
                        <td>{d2E2.eventDate}</td>
                        <td>{d2E2.eventTime}</td>
                    </tr> 
                    <tr> 
                        <td><span className="race-event" >{raceWeekendEvents.day3Event.Title}</span></td>
                        <td><span className="race-event" >{d3E.eventDate}</span></td>
                        <td><span className="race-event" >{d3E.eventTime}</span></td>
                    </tr>            
                </tbody>
            </table>
        </>
    )                              

}
