import { createSlice } from '@reduxjs/toolkit'

export const driversStandingsEditableSlice = createSlice({
  name: 'driversStandingsEditable',
  initialState: {
    value: false
  },
  reducers: {
    /*setDriversStandingsEditableState: (state, action) => {
      state.value = true
    },
    resetDriversStandingsEditableState: (state, action) => {
      state.value = false;
    },*/
    allowEditToggle: (state, action) => {
      state.value = !state.value
    }
  }
})
export const { allowEditToggle } = driversStandingsEditableSlice.actions
export default driversStandingsEditableSlice.reducer

export const selectDriversStandingsEditableState = (state) => state.driversStandingsEditable.value