import ReactDOM from 'react-dom';

import driversStandingsData from '../data-sources/2023V2_drivers_standings_input.json';
import driversStatisticsData from '../data-sources/2023V2_Driverstats.json';
import constructorsStandingsData from '../data-sources/2023V2_constructors_standings_input.json';
import driversPhotoData from '../data-sources/driver_photos_links.json';

import NavBarClass from '../NavBarf1allday';

import { useState, useRef, useEffect  } from 'react';
import { Fragment } from 'react';

import ordinal from 'ordinal';


export default function HeadToHeadFunction() {
    
    const driversRedBull = [{driver1: 'Max VERSTAPPEN', driver2: 'Sergio PEREZ'}];
    const driversMcLaren = [{driver1: 'Lando NORRIS', driver2: 'Oscar PIASTRI'}];
    const driversFerrari = [{driver1: 'Charles LECLERC', driver2: 'Carlos SAINZ'}];    
    const driversMercedes = [{driver1: 'George RUSSELL', driver2: 'Lewis HAMILTON'}];
    const driversAstonMartin  = [{driver1: 'Fernando ALONSO', driver2: 'Lance STROLL'}];
    const driversAlphaTauri = [{driver1: 'Yuki TSUNODA', driver2: 'Daniel RICCIARDO'}];
    const driversHass = [{driver1: 'Nico HULKENBERG', driver2: 'Kevin MAGNUSSEN'}];
    const driversAlpine = [{driver1: 'Pierre GASLY', driver2: 'Esteban OCON'}];
    const driversWilliams = [{driver1: 'Alexander ALBON', driver2: 'Logan SARGEANT'}];
    const driversAlfaRomeo = [{driver1: 'ZHOU Guanyu', driver2: 'Valtteri BOTTAS'}];

    return (
        <>
            <NavBarClass/>
            <H2HContainer drivers={driversRedBull}/>
            <div className="div-divider"/>
            <H2HContainer drivers={driversFerrari}/>
            <div className="div-divider"/>  
            <H2HContainer drivers={driversMcLaren}/>
            <div className="div-divider"/>
            <H2HContainer drivers={driversMercedes}/>
            <div className="div-divider"/>             
            <H2HContainer drivers={driversAstonMartin}/>
            <div className="div-divider"/> 
            <H2HContainer drivers={driversAlphaTauri}/> 
            <div className="div-divider"/> 
            <H2HContainer drivers={driversHass}/>
            <div className="div-divider"/>
            <H2HContainer drivers={driversAlpine}/>
            <div className="div-divider"/>
            <H2HContainer drivers={driversWilliams}/>
            <div className="div-divider"/>            
            <H2HContainer drivers={driversAlfaRomeo}/>
            <div className="div-divider"/>   
        </>
    );
}

function H2HContainer({drivers}){

    const d1 = drivers[0]["driver1"]
    const d2 = drivers[0]["driver2"]

    const driverPositions = getDriverPositions(drivers);
    const driverRaceH2H = getRaceFinishesHeadToHead(drivers);

    
    const driverPoints = getDriverPoints(drivers);
    const driverRaceWins = getDriverRaceWins(drivers);
    const driverPodiums = getDriverPodiums(drivers);

    const driverFastestLaps = getFastestLapsHeadToHead(drivers);
    const driverSprintH2H = getSprintFinishesHeadToHead(drivers);

    const driverQualiH2H = getQualiHeadToHead(drivers);

    const driverQualiMulti = getMultiQualiHeadToHeadMetrics(drivers);

    return (
        <div className="container h2hcontainer">
            <div className="row col-12 h2hcontainer-row">
                <div className="col">
                    <H2HPhotos driver1={d1} driver2={d2}/>
                </div>
            </div>
            <div className="row col-12 h2hcontainer-row">
                <div className="col-1 h6-driverLabel">{d1} </div>

                <div className="col-10">
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HPositions driver1Position={driverPositions.driver1Position} driver2Position={driverPositions.driver2Position}/>
                        </div>
                    </div>                    
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Points"} driver1Value={driverPoints.driver1Points} driver2Value={driverPoints.driver2Points}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Race Wins"} driver1Value={driverRaceWins.driver1RaceWins} driver2Value={driverRaceWins.driver2RaceWins}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Podiums"} driver1Value={driverPodiums.driver1Podiums} driver2Value={driverPodiums.driver2Podiums}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Fastest Laps"} driver1Value={driverFastestLaps.driver1Count} driver2Value={driverFastestLaps.driver2Count}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Race Head to Head"} driver1Value={driverRaceH2H.driver1Count} driver2Value={driverRaceH2H.driver2Count}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Team Points %"} driver1Value={driverPoints.driver1TeamContribution} 
                            driver2Value={driverPoints.driver2TeamContribution} valueSuffix='%'/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Sprint Head to Head"} driver1Value={driverSprintH2H.driver1Count} driver2Value={driverSprintH2H.driver2Count}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Quali Head to Head"} driver1Value={driverQualiH2H.driver1Count} driver2Value={driverQualiH2H.driver2Count}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Poles"} driver1Value={driverQualiMulti.polesCountDriver1} driver2Value={driverQualiMulti.polesCountDriver2}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Best Race Finish"} driver1Value={driverRaceH2H.driver1BestFinish} driver2Value={driverRaceH2H.driver2BestFinish} valuePrefix='P'/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Q3 Appearances"} driver1Value={driverQualiMulti.q3CountDriver1} driver2Value={driverQualiMulti.q3CountDriver2}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Q2 Appearances"} driver1Value={driverQualiMulti.q2CountDriver1} driver2Value={driverQualiMulti.q2CountDriver2}/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                                <div className="div-divider"/>
                        </div>
                    </div>
                    <div className="row col-10 h2hcontainer-row">
                        <div className="col">
                            <H2HMetric metricName={"Best Quali"} driver1Value={driverQualiMulti.bestQualiDriver1} driver2Value={driverQualiMulti.bestQualiDriver2} valuePrefix='P'/>
                        </div>
                    </div>
                </div>
                <div className="col-1 h6-driverLabel">{d2}</div>
            </div>
            
        </div>
    )    
}

function getMultiQualiHeadToHeadMetrics(drivers){

    const driver1Qualis =  JSON.parse((driversStatisticsData.find(x => x.driver === drivers[0]['driver1']).qualification));
    const driver2Qualis =  JSON.parse((driversStatisticsData.find(x => x.driver === drivers[0]['driver2']).qualification));

    const driver1Summary = parseQualiJson(driver1Qualis);
    const driver2Summary = parseQualiJson(driver2Qualis);    

    return {
        'polesCountDriver1': driver1Summary.polesCount, 'polesRacesDriver1' : driver1Summary.polesRaces, 'q3CountDriver1': driver1Summary.q3Count, 
            'q3RacesDriver1': driver1Summary.q3Races, 'q2CountDriver1': driver1Summary.q2Count, 'q2RacesDriver1': driver1Summary.q2Races,
            'bestQualiDriver1': driver1Summary.bestQuali,
        'polesCountDriver2': driver2Summary.polesCount, 'polesRacesDriver2' : driver2Summary.polesRaces, 'q3CountDriver2': driver2Summary.q3Count, 
            'q3RacesDriver2': driver2Summary.q3Races, 'q2CountDriver2': driver2Summary.q2Count, 'q2RacesDriver2': driver2Summary.q2Races,
            'bestQualiDriver2': driver2Summary.bestQuali
    }

}

function parseQualiJson(driverQualis, driverNumber){

    let polesCount = 0
    let polesRaces = []
    let q3Count = 0
    let q3Races = []
    let q2Count = 0
    let q2Races = []
    let allPositions = []

    for (let i = 0; i < driverQualis.length; ++i){

        if ( Object.values(driverQualis[i])[0] > 0){
            allPositions.push(Object.values(driverQualis[i])[0])
        }
        
        if ( Object.values(driverQualis[i])[0] === 1){
            polesCount += 1;
            polesRaces.push(Object.keys(driverQualis[i])[0])
            
        }
        if ( Object.values(driverQualis[i])[0] <= 10 ){
            q3Count += 1;
            q3Races.push(Object.keys(driverQualis[i])[0])
        }
        if ( Object.values(driverQualis[i])[0] <= 15){
            q2Count += 1;
            q2Races.push(Object.keys(driverQualis[i])[0])
        }
        
    }

    const bestQuali = Math.min(... allPositions)

    return {'polesCount': polesCount, 'polesRaces' : polesRaces, 'q3Count': q3Count, 'q3Races': q3Races, 'q2Count': q2Count, 'q2Races': q2Races, 'bestQuali': bestQuali}
}

function getRaceFinishesHeadToHead(drivers){

    let driver1BetterCount = 0;
    let driver1BetterRaces = [];

    let driver2BetterCount = 0;
    let driver2BetterRaces = [];

    let allFinishesDriver1 = [];
    let allFinishesDriver2 = [];

    const driver1RaceFinishes = JSON.parse(driversStatisticsData.find(x => x.driver === drivers[0]['driver1']).racefinishes);
    const driver2RaceFinishes = JSON.parse(driversStatisticsData.find(x => x.driver === drivers[0]['driver2']).racefinishes);

    //Get all race finishes for driver

    for (let i = 0; i < driver1RaceFinishes.length; ++i){
        if(Object.values(driver1RaceFinishes[i])[0] > 0)
            allFinishesDriver1.push(Object.values(driver1RaceFinishes[i])[0])
    }
    for (let i = 0; i < driver2RaceFinishes.length; ++i){
        if(Object.values(driver2RaceFinishes[i])[0] > 0)
            allFinishesDriver2.push(Object.values(driver2RaceFinishes[i])[0])
    }

    for (let i = 0; i < driver1RaceFinishes.length; ++i){

        let driver1Event = Object.keys(driver1RaceFinishes[i])[0]
        let checkDriver2Event = driver2RaceFinishes.find(x => Object.keys(x)[0] === driver1Event);

        if(!checkDriver2Event)
            continue;
        
        let driver2EventResult = Object.keys(checkDriver2Event)


        let driver2Event;

        if(!driver2EventResult)
            continue
        
        driver2Event = driver2EventResult[0]
        

        if( !(driver1Event === driver2Event) ){
            continue
        }

        let driver1Result = Object.values( driver1RaceFinishes.find(x => Object.keys(x)[0] === driver1Event) )[0] ;
        let driver2Result = Object.values( driver2RaceFinishes.find(x => Object.keys(x)[0] === driver2Event) )[0] ;
        

        /*if( driver1Result > 0)
            allFinishesDriver1.push(driver1Result)

        if(driver2Result > 0)
            allFinishesDriver2.push(driver2Result)*/
        
        if( ( driver1Result > 0) && ( 
            driver1Result < driver2Result) ||
            (driver1Result > 0) && (driver2Result <= 0) ){

            driver1BetterCount += 1
            driver1BetterRaces.push(driver1Event )
        }
        else if( ( driver2Result > 0) && ( 
            driver2Result < driver1Result) ||
            ( driver2Result > 0) && driver1Result <= 0) {

            driver2BetterCount += 1
            driver2BetterRaces.push(driver2Event)
        }
        
    }

    const bestRaceResultDriver1 = Math.min(... allFinishesDriver1);
    const bestRaceResultDriver2 = Math.min(... allFinishesDriver2);

    return {'driver1Count': driver1BetterCount, 'driver2Count': driver2BetterCount, 
            'driver1BetterRaces': driver1BetterRaces, 'driver2BetterRaces': driver2BetterRaces,
            'driver1BestFinish': bestRaceResultDriver1, 'driver2BestFinish': bestRaceResultDriver2}
}


function getSprintFinishesHeadToHead(drivers){

    let driver1BetterCount = 0
    let driver1BetterSprints = []

    let driver2BetterCount = 0
    let driver2BetterSprints = []

    const driver1SprintFinishes = JSON.parse(driversStatisticsData.find(x => x.driver === drivers[0]['driver1']).sprintfinishes);
    const driver2SprintFinishes = JSON.parse(driversStatisticsData.find(x => x.driver === drivers[0]['driver2']).sprintfinishes);

    for (let i = 0; i < driver1SprintFinishes.length; ++i){

        let driver1Event = Object.keys(driver1SprintFinishes[i])[0]
        let checkDriver2Event = driver2SprintFinishes.find(x => Object.keys(x)[0] === driver1Event);

        if(!checkDriver2Event)
            continue;
        
        let driver2EventResult = Object.keys(checkDriver2Event)


        let driver2Event;

        if(!driver2EventResult)
            continue
        
        driver2Event = driver2EventResult[0]
        

        if( !(driver1Event === driver2Event) ){
            continue
        }

        let driver1Result = Object.values( driver1SprintFinishes.find(x => Object.keys(x)[0] === driver1Event) )[0] ;
        let driver2Result = Object.values( driver2SprintFinishes.find(x => Object.keys(x)[0] === driver2Event) )[0] ;
        
        
        if( ( driver1Result > 0) && ( 
            driver1Result < driver2Result) ||
            (driver1Result > 0) && (driver2Result <= 0) ){

            driver1BetterCount += 1
            driver1BetterSprints.push(driver1Event )
        }
        else if( ( driver2Result > 0) && ( 
            driver2Result < driver1Result) ||
            ( driver2Result > 0) && driver1Result <= 0) {

            driver2BetterCount += 1
            driver2BetterSprints.push(driver2Event)
        }
        
        
    }

    return {'driver1Count': driver1BetterCount, 'driver2Count': driver2BetterCount, 
            'driver1BetterSprints': driver1BetterSprints, 'driver2BetterSprints': driver2BetterSprints}
}


function getFastestLapsHeadToHead(drivers){

    const driver1FastestLaps =  driversStatisticsData.find(x => x.driver === drivers[0]['driver1']).fastestlaps;
    const driver2FastestLaps =  driversStatisticsData.find(x => x.driver === drivers[0]['driver2']).fastestlaps;

    return {'driver1Count': driver1FastestLaps.length, 'driver2Count':driver2FastestLaps.length, 
            'driver1FastestLapsRaces': driver1FastestLaps, 'driver2FastestLapsRaces': driver1FastestLaps}    
}


function getQualiHeadToHead(drivers){

    let driver1BetterCount = 0
    let driver1BetterQualis = []

    let driver2BetterCount = 0
    let driver2BetterQualis = []

    const driver1Qualis = JSON.parse(driversStatisticsData.find(x => x.driver === drivers[0]['driver1']).qualification);
    const driver2Qualis = JSON.parse(driversStatisticsData.find(x => x.driver === drivers[0]['driver2']).qualification);

    for (let i = 0; i < driver1Qualis.length; ++i){

        let driver1Event = Object.keys(driver1Qualis[i])[0]
        let checkDriver2Event = driver2Qualis.find(x => Object.keys(x)[0] === driver1Event);

        if(!checkDriver2Event)
            continue;
        
        let driver2EventResult = Object.keys(checkDriver2Event)


        let driver2Event;

        if(!driver2EventResult)
            continue
        
        driver2Event = driver2EventResult[0]
        

        if( !(driver1Event === driver2Event) ){
            continue
        }

        let driver1Result = Object.values( driver1Qualis.find(x => Object.keys(x)[0] === driver1Event) )[0] ;
        let driver2Result = Object.values( driver2Qualis.find(x => Object.keys(x)[0] === driver2Event) )[0] ;
        
        if( ( driver1Result > 0) && ( 
            driver1Result < driver2Result) ||
            (driver1Result > 0) && (driver2Result <= 0) ){

            driver1BetterCount += 1
            driver1BetterQualis.push(driver1Event )
        }
        else if( ( driver2Result > 0) && ( 
            driver2Result < driver1Result) ||
            ( driver2Result > 0) && driver1Result <= 0) {

            driver2BetterCount += 1
            driver2BetterQualis.push(driver2Event)
        }
        
    }

    return {'driver1Count': driver1BetterCount, 'driver2Count': driver2BetterCount, 
            'driver1BetterRaces': driver1Qualis, 'driver2BetterRaces': driver2Qualis}
}




function getDriverPositions(drivers){

    const driver1Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver1']).Pos);
    const driver2Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver2']).Pos);

    return({"driver1Position": driver1Object, "driver2Position": driver2Object });

}

function getDriverPoints(drivers){

    const driver1Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver1']).Points);
    const driver2Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver2']).Points);

    var driver1TeamContribution = Math.round(driver1Object/(driver1Object + driver2Object) * 100);
    var driver2TeamContribution = Math.round(driver2Object/(driver1Object + driver2Object) * 100);

    if( !Number.isFinite(driver1TeamContribution) )
        driver1TeamContribution = 0;
    if( !Number.isFinite(driver2TeamContribution) )
        driver2TeamContribution = 0;

    return({"driver1Points": driver1Object, "driver2Points": driver2Object, 
        'driver1TeamContribution': driver1TeamContribution, 'driver2TeamContribution': driver2TeamContribution });

}

function getDriverRaceWins(drivers){

    const driver1Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver1']).Wins);
    const driver2Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver2']).Wins);

    return({"driver1RaceWins": driver1Object, "driver2RaceWins": driver2Object });
}

function getDriverPodiums(drivers){

    const driver1Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver1']).Podiums);
    const driver2Object = parseInt(driversStandingsData.find(x => x.Driver === drivers[0]['driver2']).Podiums);

    return({"driver1Podiums": driver1Object, "driver2Podiums": driver2Object });
}

function calculateMetricProportion(metricValue1, metricValue2, maxValue){    

    let value1 = metricValue1/(metricValue1 + metricValue2) * maxValue;
    let value2 = metricValue2/(metricValue1 + metricValue2) * maxValue

    return {"Value1": value1, "Value2": value2};
}

function H2HPositions({driver1Position, driver2Position}) {
    
    const level = 40
    const barHeight = 30
    const scale = 50    
    
    return (
        <div>
            <h6 className="metricLabel">Position</h6>
            <div className="row col-xs-12 " cellSpacing="0">
                <div className="col div-h2hmetric">
                    <div className="progress flex-row-reverse h2hmetric" style={{height: barHeight}}>
                        <div className="progress-bar bg-success metric-progress-bar" style={{width: `${scale}%`}} role="progressbar" >{ordinal(driver1Position)}</div>
                    </div>
                </div>
                <div className="col div-h2hmetric">
                    <div className="progress h2hmetric" style={{height: barHeight}}>
                        <div className="progress-bar bg-info metric-progress-bar" style={{width: `${scale}%`}} role="progressbar" >{ordinal(driver2Position)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function H2HMetric({metricName, driver1Value, driver2Value, valuePrefix='', valueSuffix=''}) {
    
    const level = 40
    const barHeight = 30

    const values = calculateMetricProportion(driver1Value, driver2Value, 100);

    let value1 = values["Value1"] > 20  ?  values["Value1"] : 20;
    let value2 = values["Value2"] > 20  ?  values["Value2"] : 20;

    value1 = (valuePrefix === '') && (valueSuffix === '') ? value1 : value1 + 30;
    value2 = (valuePrefix === '') && (valueSuffix === '') ? value2 : value2 + 30;

    value1 = (valuePrefix === 'P') ? 50 : value1;
    value2 = (valuePrefix === 'P') ? 50 : value2;


    let driver1ValueAndPrefix = valuePrefix.concat(driver1Value);
    let driver2ValueAndPrefix = valuePrefix.concat(driver2Value);

    driver1ValueAndPrefix = driver1ValueAndPrefix.concat(valueSuffix);
    driver2ValueAndPrefix = driver2ValueAndPrefix.concat(valueSuffix);
   
    
    return (
        <div>
            <h6 className="metricLabel">{metricName}</h6>
            <div className="row col-xs-12 " cellSpacing="0">
                <div className="col div-h2hmetric">
                    <div className="progress flex-row-reverse h2hmetric" style={{height: barHeight}}>
                        <div className="progress-bar bg-success metric-progress-bar" style={{width: `${value1}%`}} role="progressbar" >{driver1ValueAndPrefix}</div>
                    </div>
                </div>
                <div className="col div-h2hmetric">
                    <div className="progress h2hmetric" style={{height: barHeight}}>
                        <div className="progress-bar bg-info metric-progress-bar" style={{width: `${value2}%`}} role="progressbar" >{driver2ValueAndPrefix}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function H2HPhotos({driver1, driver2}) {

    const photo1 = driversPhotoData[driver1]
    const photo2 = driversPhotoData[driver2]
    
    return (
        <>
            <div className="row col-xs-12 ml-xs-0 ml-md-5" cellSpacing="0">
                <div className="col img-fluid-h2hphoto">
                    <img src={photo1.Driver.Link} alt={photo1.Driver.Name} className="img-fluid-h2hphoto" />
                </div>
                {<div className="col img-fluid-h2hphoto">
                    <img src={photo2.Driver.Link} alt={photo2.Driver.Name} className="img-fluid-h2hphoto" />
                </div>}
            </div>
        </>
        
    );
}