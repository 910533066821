import NavBarClass from '../NavBarf1allday';
import store from './redux/adminpages/store'
import { Provider } from 'react-redux'

import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import {
    selectConstructorsStandingsEditableState,
    allowEditToggle
} from './redux/adminpages/constructorsStandingsEditableSlice'
import{
    selectStandingsTable,
    updateStandingsRow,
    setStandingsTableData
} from './redux/adminpages/constructorsStandingsRowsSlice'

import {callApiPostStandingsUpdate} from './middleware/callTableStandingsApi'

export default function AdminConstructorsStandingsFunction () {
    
    return (
        <>
            <Provider store={store}>
                <NavBarClass/>
                <EditConfirmationSection/>
                <EditableConstructorsStandingsTable/>             
            </Provider>                 
        </>
    );
}

function EditConfirmationSection(){

    const dispatch = useDispatch();    

    const isEditable = useSelector(selectConstructorsStandingsEditableState)  

    const tableStandings = useSelector(selectStandingsTable);

    const onConfirm = () => {
        callApiToUpdateTable(tableStandings);
        dispatch(allowEditToggle());
    };
    
    const onEditToggle = () => {
        dispatch(allowEditToggle());
    };

    return (
        <div className="d-flex  mb-3 divDStandingsEditConfirmSection">
          <button className="btn btn-primary me-2" onClick={onEditToggle}>
            {isEditable ? 'Disable Edit' : 'Enable Edit'}
          </button>
          <button className="btn btn-success" onClick={onConfirm} disabled={!isEditable}>
            Confirm Changes
          </button>
          <div className="col-3 backToAdminButtonSection">
            <a href="/admin" className="btn btn-secondary btn-lg backToAdminButton" role="button">{'Admin Page'}</a>                    
          </div>

        </div>
    );
}

async function callApiToUpdateTable(data){
  
    console.log(`in api caller, table[0] = ${JSON.stringify(data[0])}`)
    const response = await callApiPostStandingsUpdate(data)
    return "DONE"
}

function EditableConstructorsStandingsTable(){
    
    const dispatch = useDispatch();
    
    const handleEdit = (index, key, value) => {
        dispatch(updateStandingsRow({ index, key, value }));
    };

    const tableStandings = useSelector(selectStandingsTable);
    const isEditable = useSelector(selectConstructorsStandingsEditableState) 
    
    return (
        <div className="container mt-5">
            <h3>Constructors Standings</h3>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Pos</th>
                  <th>Team</th>
                  <th>Points</th>    
                  <th>Wins</th>
                  <th>Podiums</th>
                </tr>
              </thead>
              <tbody>
                {tableStandings.map((row, index) => (
                  <tr key={index}>
                    {Object.keys(row).map((key) => (
                      <td key={key}>
                      {isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          value={row[key]}
                          onChange={(e) => handleEdit(index, key, e.target.value)}
                        />
                      ) : (
                        row[key]
                      )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
    )
    
}