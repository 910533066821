import { configureStore } from '@reduxjs/toolkit'
import selectedRaceReducer from './selectedRaceSlice'
import editModeReducer from './editModeSlice'
import vmStateReducer from './vmStateSlice'
import dataloadJobReducer from './dataloadJobSlice'
import dataloadYearReducer from './dataloadYearSlice'
import dataloadRoundReducer from './dataloadRoundSlice'
import driversStandingsEditableReducer from './driversStandingsEditableSlice'
import driversStandingsRowsReducer from './driversStandingsRowsSlice' 
import constructorsStandingsEditableReducer from './constructorsStandingsEditableSlice'
import constructorsStandingsRowsReducer from './constructorsStandingsRowsSlice' 

//{/*driversStandingsRows: driversStandingsRowsReducer*/}

export default configureStore({
  

  reducer: {
    selectedRace: selectedRaceReducer,
    editMode: editModeReducer,
    vmControlState: vmStateReducer,
    dataloadJob: dataloadJobReducer,
    dataloadYear: dataloadYearReducer,
    dataloadRound: dataloadRoundReducer,
    driversStandingsEditable: driversStandingsEditableReducer,
    driversStandingsRows: driversStandingsRowsReducer,
    constructorsStandingsEditable: constructorsStandingsEditableReducer,
    constructorsStandingsRows: constructorsStandingsRowsReducer

    
  }
})