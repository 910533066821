import { createSlice } from '@reduxjs/toolkit'

export const selectedRaceSlice = createSlice({
  name: 'selectedRace',
  initialState: {
    value: 'Select Race'
  },
  reducers: {    
    resetRaceSelection: state => {
      state.value = "Select Race"
    },
    updateRaceSelection: (state, action) => {
      state.value = action.payload
    }
  }
})
export const { resetRaceSelection, updateRaceSelection } = selectedRaceSlice.actions
export default selectedRaceSlice.reducer

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSelectedRace = state => state.selectedRace.value



