
import axios from 'axios';
import {properties} from '../resource/properties';

export function callApiPostRaceSchedule(body){

    //console.log("Payload Body is " + body)

    const headers = {
        "accept": "*/*",
        "content-type": "application/json"
    }


    axios.post(properties.POST_API_GATEWAY_URL,
        body, {headers: headers})

    .then(function (response) {
        console.log(response);
        console.log("Post url is " + properties.POST_API_GATEWAY_URL)
        
    })
    .catch(function (error) {
        console.log(error);
    })
    .finally(function () {
        
    });

    return "api"
}

export function callAPIGetRaceSchedule(params){
    console.log("Params are " + params)
    axios.get(properties.GET_API_GATEWAY_URL, {

        headers:{},

        params: params

    })
    .then(function (response) {
        console.log(response);       
        console.log("Get url is " + properties.GET_API_GATEWAY_URL)
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        
      });

    return "api"
}

export function callApiUtibePost(){

    const headers = {
        "accept": "*/*",
        
        "cache-control": "no-cache",
        "content-type": "application/json",
        "postman-token": "211a4748-2bd7-4ff7-9953-f6aa4227c15e",
        "x-amzn-trace-id": "Root=1-669674ef-0a2a34c048f727717ead1c10",
        "x-forwarded-for": "86.139.105.244",
        "x-forwarded-port": "443",
        "x-forwarded-proto": "https",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
    }


    axios.post(properties.POST_API_GATEWAY_URL, {               
    
            "title": "Bahrain",
            "weekendRaceDateFromToText": "29th Feb to 2nd Mar",
            "nextRaceCheckBox": "false",
            "day1Event1": "Free Practice 1 ### 2024-02-29T15:00:00",
            "day1Event2": "Free Practice 2  ### 2024-03-01T12:30:00",
            "day2Event1": "Free Practice 3  ### 2024-03-01T12:30:00",
            "day2Event2": "Qualifying  ### 2024-03-01T16:00:00",
            "day3Event": "Bahrain Grand Prix  ### 2024-03-02T15:00:00",                
            "routeKey": "POST /update-race"            
       
        
    }, {headers: headers})
    .then(function (response) {
        console.log(response);
        console.log("Post url is " + properties.POST_API_GATEWAY_URL)
        console.log("Get url is " + properties.GET_API_GATEWAY_URL)
    })
    .catch(function (error) {
        console.log(error);
    })
    .finally(function () {
        
    });

    return "api"
}

export function callApiUtibeGet(){
    axios.get(properties.GET_API_GATEWAY_URL, {

        headers:{},

        params: {
            "title": "Bahrain",
            "weekendRaceDateFromToText": "29th Feb to 2nd Mar",
            "nextRaceCheckBox": "false",
            "day1Event1": "Free Practice 1 ### 2024-02-29T15:00:00",
            "day1Event2": "Free Practice 2  ### 2024-03-01T12:30:00",
            "day2Event1": "Free Practice 3  ### 2024-03-01T12:30:00",
            "day2Event2": "Qualifying  ### 2024-03-01T16:00:00",
            "day3Event": "Bahrain Grand Prix  ### 2024-03-02T15:00:00"
        }

    })
    .then(function (response) {
        // handle success
        console.log(response);
        console.log("Post url is " + properties.POST_API_GATEWAY_URL)
        console.log("Get url is " + properties.GET_API_GATEWAY_URL)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

    return "api"
}

