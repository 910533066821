
import '../App.css';
import React from 'react';
import '../index.css';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import MainRootClass from './Body';
import StandingsTableClass from './Standingsf1allday';
import ScheduleClass from './Schedulef1allday';
import HeadToHeadFunction from './HeadToHeadf1allday';

import RoughTestFunction from './RoughTests'

import AdminPageFunction from './Admin'
import ScheduleAdminPageFunction from './ScheduleAdmin'
import AdminDataLoadPageFunction from './AdminDataLoad'
import AdminDriversStandingsFunction from './AdminDriversStandings'
import AdminConstructorsStandingsFunction from './AdminConstructorsStandings'

import LoginPage from './cognito/LoginPage';


function App() {

  const isAuthenticated = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    return !!accessToken;
  };

  console.log(`isAuthenticated = ${isAuthenticated()}`)

  return (
    
  <BrowserRouter>
    <Routes>
      <Route index element={<MainRootClass/>} />
      <Route path="home" element={<MainRootClass/>} />
      <Route path="standingstable" element={<StandingsTableClass/>} />
      <Route path="schedule" element={<ScheduleClass/>} />
      <Route path="head-to-head" element={<HeadToHeadFunction/>} />
      {/*<Route path="admin" element={<AdminPageFunction/>} />*/}
      <Route path="login" element={<LoginPage/>} />
      {/*<Route path="rough-test" element={<RoughTestFunction/>} />*/}
      {/*<Route path="admin" element={<AdminPageFunction/>} />*/}
      {/*<Route path="admin-schedule" element={<ScheduleAdminPageFunction/>} />*/}
      {/*<Route path="admin" element={<ProtectedRoute element={AdminPageFunction}/>} />*/}
      {/*<Route path="admin-schedule" element={<ProtectedRoute element={ScheduleAdminPageFunction}/>} />*/}

      <Route path="admin" element={isAuthenticated() ? <AdminPageFunction /> : <Navigate replace to="/login" />} />
      <Route path="admin-schedule" element={isAuthenticated() ? <ScheduleAdminPageFunction /> : <Navigate replace to="/login" />} />
      <Route path="admin-dataload" element={isAuthenticated() ? <AdminDataLoadPageFunction /> : <Navigate replace to="/login" />} />
      <Route path="admin-driversstandings" element={isAuthenticated() ? <AdminDriversStandingsFunction /> : <Navigate replace to="/login" />} />
      <Route path="admin-constructorsstandings" element={isAuthenticated() ? <AdminConstructorsStandingsFunction /> : <Navigate replace to="/login" />} />
      
    </Routes>
  </BrowserRouter>

);

}


export default App;