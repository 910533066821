import { createSlice } from '@reduxjs/toolkit'
import driversStandingsData from '../../../data-sources/2023V2_drivers_standings_input.json';



export const driversStandingsRowsSlice = createSlice({
  name: 'driversStandingsRows',
  initialState: {
    value: driversStandingsData
  },
  reducers: {
    updateStandingsRow: (state, action) => {
      const { index, key, value } = action.payload;
      state.value[index][key] = value;       
    },
    setStandingsTableData: (state, action) => {
      return action.payload;
    }
  }
})
export const { updateStandingsRow, setStandingsTableData } = driversStandingsRowsSlice.actions
export default driversStandingsRowsSlice.reducer

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectStandingsTable = state => state.driversStandingsRows.value



