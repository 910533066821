import NavBarClass from '../NavBarf1allday';
import driversStandingsData from '../data-sources/2023V2_drivers_standings_input.json';
import store from './redux/adminpages/store'
import { Provider } from 'react-redux'

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import {
    selectDriversStandingsEditableState,
    allowEditToggle
} from './redux/adminpages/driversStandingsEditableSlice'
import{
    selectStandingsTable,
    updateStandingsRow,
    setStandingsTableData
} from './redux/adminpages/driversStandingsRowsSlice'

import {callApiPostStandingsUpdate} from './middleware/callTableStandingsApi'

export default function AdminDriversStandingsFunction() {
    
    return (
        <>
            <Provider store={store}>
                <NavBarClass/>
                <EditConfirmationSection/>
                <EditableDriversStandingsTable/>             
            </Provider>                 
        </>
    );
}

function EditConfirmationSection(){

    const dispatch = useDispatch();    

    const isEditable = useSelector(selectDriversStandingsEditableState)  

    const tableStandings = useSelector(selectStandingsTable);

    const onConfirm = () => {
        callApiToUpdateTable(tableStandings);
        dispatch(allowEditToggle());
    };
    
    const onEditToggle = () => {
        dispatch(allowEditToggle());
    };

    return (
        <div className="d-flex  mb-3 divDStandingsEditConfirmSection">
          <button className="btn btn-primary me-2" onClick={onEditToggle}>
            {isEditable ? 'Disable Edit' : 'Enable Edit'}
          </button>
          <button className="btn btn-success" onClick={onConfirm} disabled={!isEditable}>
            Confirm Changes
          </button>
          <div className="col-3 backToAdminButtonSection">
            <a href="/admin" className="btn btn-secondary btn-lg backToAdminButton" role="button">{'Admin Page'}</a>                    
          </div>

        </div>
    );
}

async function callApiToUpdateTable(data){

    //const tableStandings = useSelector(selectStandingsTable);
    console.log(`in api caller, table[0] = ${JSON.stringify(data[0])}`)
    const response = await callApiPostStandingsUpdate(data)

    /*const currentEC2State = await callApiGetDataload(buildApiPayload("N/A"))
    dispatch(setVmState(currentEC2State.data));

    console.log(`running sync ${Date.now()}`)

    const vmSliderState = currentEC2State.data
    const switchElement = document.getElementById("vmControlSwitch");

    if (switchElement) {
        switchElement.checked = (vmSliderState === 'EC2_STARTING' || vmSliderState === 'EC2_STARTED');
    }*/
    

    return "DONE"
}

function EditableDriversStandingsTable(){
    
    const dispatch = useDispatch();
    
    const handleEdit = (index, key, value) => {
        dispatch(updateStandingsRow({ index, key, value }));
    };

    const tableStandings = useSelector(selectStandingsTable);
    const isEditable = useSelector(selectDriversStandingsEditableState) 
    
    return (
        <div className="container mt-5">
            <h3>Driver Standings</h3>
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Pos</th>
                  <th>Driver</th>
                  <th>Points</th>    
                  <th>Wins</th>
                  <th>Podiums</th>
                  <th>Team</th>
                </tr>
              </thead>
              <tbody>
                {tableStandings.map((row, index) => (
                  <tr key={index}>
                    {Object.keys(row).map((key) => (
                      <td key={key}>
                      {isEditable ? (
                        <input
                          type="text"
                          className="form-control"
                          value={row[key]}
                          onChange={(e) => handleEdit(index, key, e.target.value)}
                        />
                      ) : (
                        row[key]
                      )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
    )
    
}