import NavBarClass from '../NavBarf1allday';
import grandPrixScheduleData from '../data-sources/grand_prix_schedule.json';
import { Fragment, useEffect  } from 'react'

import store from './redux/adminpages/store'

import { Provider } from 'react-redux'

import { useSelector, useDispatch } from 'react-redux'
import {
    resetRaceSelection,
    updateRaceSelection,
    selectSelectedRace
} from './redux/adminpages/selectedRaceSlice'
import {
    resetEditMode,
    selectEditMode,
    setEditMode
} from './redux/adminpages/editModeSlice'


import {callApiPostRaceSchedule, callAPIGetRaceSchedule} from './middleware/callApi';

export default function ScheduleAdminPageFunction() {
    return (
        <>
            <Provider store={store}>
                <NavBarClass/>
                <ChangesButtonsSection/>
                <RaceHeadingSection />  
                <EventDetailsSection/>
            </Provider>                 
        </>
    );
}

function buildApiPayload(document, selectedRace){
    var payload = {};
    
    if(selectedRace == 'Select Race')
        return payload
            
    payload['title'] = selectedRace
    payload['weekendRaceDateFromToText'] = document.getElementById('weekendRaceDateFromToText').value 
    payload['nextRaceCheckBox'] = document.getElementById('nextRaceCheckBox').checked ? 'true' : 'false'
    payload['day1Event1'] = document.getElementById('day1Event1_title').value + "###" + document.getElementById('day1Event1_dateTime').value
    payload['day1Event2'] = document.getElementById('day1Event2_title').value + "###" + document.getElementById('day1Event2_dateTime').value
    payload['day2Event1'] = document.getElementById('day2Event1_title').value + "###" + document.getElementById('day2Event1_dateTime').value
    payload['day2Event2'] = document.getElementById('day2Event2_title').value + "###" + document.getElementById('day2Event2_dateTime').value
    payload['day3Event'] = document.getElementById('day3Event_title').value + "###" + document.getElementById('day3Event_dateTime').value     

    return payload;
}


function ChangesButtonsSection(){

    const disabled = !useSelector(selectEditMode)

    const dispatch = useDispatch()

    const changesButtonHandlerSetEdit = () => { 
        dispatch(setEditMode());
    }

    const selectedRace = useSelector(selectSelectedRace)
    
    const changesButtonHandlerResetEdit = () => {
        dispatch(resetEditMode());
        
        const apiPayload = buildApiPayload(document, selectedRace);
        console.log(`apiPayload is: `)
        console.log(apiPayload)
        
        //callAPIGetRaceSchedule(apiPayload);  
        callApiPostRaceSchedule(apiPayload);      
    }

    return (
        <>
            <div className="changesButtonsSection container col-6 ">                
                <div className="row ">
                    <div className="col raceHeadingCol">
                        <ChangesButton label="Edit" onClick={changesButtonHandlerSetEdit} disabled={false}/>
                    </div>
                    <div className="col raceHeadingCol">
                        <ChangesButton label="Confirm Changes" onClick={changesButtonHandlerResetEdit} disabled={disabled}/>                                                         
                    </div>
                </div>
            </div>
        </>        
    )
}

function ChangesButton({label="Edit", onClick, disabled=true}){   

    return (
        <>
            <button type="button" className="btn btn-primary btn-lg changesButton" onClick={onClick} disabled={disabled}>{label}</button>
        </>
    )
}


function RaceHeadingSection(){    
    return (
        <> 
            <div className="raceheadingsection container col-6 ">            
                <div className="row ">
                    <div className="col raceHeadingCol">  
                        <RaceSelectDropDown />                                     
                    </div>
                                    
                    <div className="col raceHeadingCol">
                        <WeekendRaceDate/>
                    </div>
                </div>
                <div className="row">
                    <div className="col raceHeadingCol">  
                        <NextRaceIndicator/>                                      
                    </div>  
                    <div className="col raceHeadingCol">   
                    </div>  
                </div>                 
            </div>        
        </>        
    )
}


function updateRaceDropDownListeners(raceDetails, elementId, jsonPath ){
    const element = document.getElementById(elementId);
    const jsonValue = getValueFromPath(raceDetails, jsonPath)

    if(elementId === 'nextRaceCheckBox'){
        if(jsonValue === 'true'){
            element.checked = true;
        }
        else{
            element.checked = false;
        }
    }
    else{
        element.value = jsonValue;
    } 
}


function getArrayItemForCondition(grandPrixScheduleData, raceTitle){
    const race = grandPrixScheduleData.find(r => r.RaceName.Title === raceTitle);
    return race;
}


const getValueFromPath = (jsonObject, path) => {
    const keys = path.split('.');
    let value = jsonObject;
    for (let key of keys) {
      if (value[key] === undefined) {
        return 'Not found';
      }
      value = value[key];
    }
    return value;
  };

const getValueFromJson = (jsonData, race, jsonPath) => {
    const paths = jsonPath.split('.');
    for (let r of jsonData) {
      let value = r;
      for (let path of paths) {
        if (value[path] !== undefined) {
          value = value[path];
        } else {
          value = undefined;
          break;
        }
      }
      if (value && value === race) {
        let dateAndTime = r;
        for (let path of jsonPath.split('.').slice(0, -1)) {
          dateAndTime = dateAndTime[path];
        }
        return dateAndTime.DateAndTime;
      }
    }
    return 'Value not found';
  };

function RaceSelectDropDown(){

    const selectedRace = useSelector(selectSelectedRace)
    const dispatch = useDispatch()

    const raceDropDownHandler = (title) => { 
        dispatch(updateRaceSelection(title));

        const raceDetails = getArrayItemForCondition(grandPrixScheduleData, title);
        updateRaceDropDownListeners(raceDetails, 'weekendRaceDateFromToText', 'RaceName.DateAndTime' );

        updateRaceDropDownListeners(raceDetails, 'day1Event1_title',  'day1Event1.Title');
        updateRaceDropDownListeners(raceDetails, 'day1Event1_dateTime',  'day1Event1.DateAndTime');

        updateRaceDropDownListeners(raceDetails, 'day1Event2_title',  'day1Event2.Title');
        updateRaceDropDownListeners(raceDetails, 'day1Event2_dateTime',  'day1Event2.DateAndTime');

        updateRaceDropDownListeners(raceDetails, 'day2Event1_title',  'day2Event1.Title');
        updateRaceDropDownListeners(raceDetails, 'day2Event1_dateTime',  'day2Event1.DateAndTime');

        updateRaceDropDownListeners(raceDetails, 'day2Event2_title',  'day2Event2.Title');
        updateRaceDropDownListeners(raceDetails, 'day2Event2_dateTime',  'day2Event2.DateAndTime');

        updateRaceDropDownListeners(raceDetails, 'day3Event_title',  'day3Event.Title');
        updateRaceDropDownListeners(raceDetails, 'day3Event_dateTime',  'day3Event.DateAndTime');

        updateRaceDropDownListeners(raceDetails, 'nextRaceCheckBox',  'RaceName.next');

        dispatch(resetEditMode());
        
    }
    
    return (
        <div className="btn-group">
            <button className="btn btn-secondary dropdown-toggle raceSelectionDropDown" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
                {selectedRace}
            </button>
            <ul className="dropdown-menu">
                {grandPrixScheduleData.map((item) => (
                    <Fragment key={item.RaceName.Title}>                        
                        <li><a className="dropdown-item" href="#" onClick={() => raceDropDownHandler(item.RaceName.Title)}>{item.RaceName.Title}</a></li>
                    </Fragment>
                ) )} 
            </ul>
        </div>
    );
}

function WeekendRaceDate({label = "Date & Time", value}){
    const disabled = !useSelector(selectEditMode)
    return (
        <div className="input-group dateAndTimeTextInput" >
            <div className="input-group-prepend">
                <span className="input-group-text">{label}</span>
            </div>
            <input className="form-control " aria-label="With textarea" id="weekendRaceDateFromToText" disabled={disabled}>{value}</input>
        </div>
    )
}

function NextRaceIndicator({value=false}){
    const disabled = !useSelector(selectEditMode)

    return (
        <div className="form-check nextRaceIndicator">
            <input type="checkbox" value="" id="nextRaceCheckBox" disabled={disabled}/>
            <label className="form-check-label nextRaceIndicatorLabel" >Next Race</label>
        </div>
    ) 
}

function EventDetailsSection(){
    return (
        <> 
            <div className="eventDetailsSection container col-6 ">
                
                <div className="row ">
                    <div className="col raceHeadingCol">  
                        <DayEventAndTime label="Day 1 Event 1" id="day1Event1" />                                     
                    </div>
                </div>
                <div className="row ">
                    <div className="col raceHeadingCol">  
                        <DayEventAndTime label="Day 1 Event 2" id="day1Event2"/>                                     
                    </div>
                </div>
                <div className="row ">
                    <div className="col raceHeadingCol">  
                        <DayEventAndTime label="Day 2 Event 1" id="day2Event1"/>                                     
                    </div>
                </div>
                <div className="row ">
                    <div className="col raceHeadingCol">  
                        <DayEventAndTime label="Day 2 Event 2" id="day2Event2"/>   
                    </div>
                </div>
                <div className="row ">
                    <div className="col raceHeadingCol">  
                        <DayEventAndTime label="Day 3 Event" id="day3Event"/>                                     
                    </div>
                </div>
                               
            </div>        
        </>        
    )
}

function DayEventAndTime({label="Day1 Event1", value, id }){
    const disabled = !useSelector(selectEditMode)
    return (
        <div className="input-group dayEventAndTimeInput">
            <div className="input-group-prepend dayEventAndTimeLabel">
                <span className="input-group-text">{label}</span>
            </div>
            <input className="form-control " id={id + "_title"} disabled={disabled}>{value}</input>
            <input className="form-control " id={id + "_dateTime"} disabled={disabled}>{value}</input>
        </div>
    )
}