import { createSlice } from '@reduxjs/toolkit'
//import vmStateJson from '../../../data-sources/admin_vm_state.json';

export const dataloadJobStateSlice = createSlice({
  name: 'dataloadJob',
  initialState: {
    value: "Select Dataload Job"
  },
  reducers: {
    setDataloadJobState: (state, action) => {
      state.value = action.payload
    },
    resetDataloadJobState: (state, action) => {
      state.value = initialState.value;
    }
  }
})
export const { setDataloadJobState, resetDataloadJobState } = dataloadJobStateSlice.actions
export default dataloadJobStateSlice.reducer

export const selectDataloadJobState = (state) => state.dataloadJob.value