
import axios from 'axios';
import {standings_table_update} from '../resource/properties';

export async function callApiPostStandingsUpdate(body) {
    const headers = {
        "accept": "*/*",
        "content-type": "application/json"
    };

    try {
        const response = await axios.post(standings_table_update.POST_API_GATEWAY_URL, body, { headers });
        console.log(`response is ${JSON.stringify(response)}`);
        //console.log("Post url is " + dataload_VM_Control.POST_API_GATEWAY_URL);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function callApiGetDataload(params) {
    const headers = {
        "accept": "*/*",
        "content-type": "application/json"
    };

    try {
        //console.log("Params are " + params)
        const response = await axios.get(dataload_VM_Control.GET_API_GATEWAY_URL,{ 
            headers:{},
            params: params
        });
        //console.log(`GET response is ${response}`);
        //console.log("Get url is " + dataload_VM_Control.POST_API_GATEWAY_URL);
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
}



