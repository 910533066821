
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from './authService';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');    
    const navigate = useNavigate();

    const handleSignIn = async () => {
        
        console.log("Attempting to login to admin section ...")
        
        try {
            //alert("ok mateweee")
            const session = await signIn(email, password);
            console.log(`Sign in successful with signin token ${session.AccessToken}, and session ${session}`, session);
            
            //alert("ok mate")
            if (session && typeof session.AccessToken !== 'undefined') {
                sessionStorage.setItem('accessToken', session.AccessToken);
                if (sessionStorage.getItem('accessToken')) {
                    window.location.href = '/admin';
                } 
                else {
                    console.error('Session token was not set properly.');
                }
            } 
            else{
                console.error('SignIn session or AccessToken is undefined.');
            }
        } 
        catch (error) {
            alert(`Sign in failed: ${error}`);
        }
    };
    

  return (
    <div className="loginForm">
        <h1>Welcome to F1allday</h1>
        <h4>Sign in to your account</h4>
        {/*<form onSubmit={handleSignIn}>*/}
            <div>
                <input className="inputText" id="email" type="email" value={email} 
                        onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
            </div>
            <div>
                <input className="inputText" id="password" type="password" value={password}
                    onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
            </div>            
            <button type="submit" onClick={handleSignIn}>Sign In</button>
        {/*</form>*/}
    </div>
  );
};

export default LoginPage;